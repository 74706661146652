import { useState } from "react"

import { StripeCardElementChangeEvent } from "@stripe/stripe-js"

export function useHandleCardChange() {
  const [cardComplete, setCardComplete] = useState(false)
  const [cardError, setCardError] = useState<string | null>("")

  function handleCardChange(event: StripeCardElementChangeEvent) {
    if (event.complete) {
      setCardComplete(true)
    } else {
      setCardComplete(false)
    }

    if (event.error) {
      setCardError(event.error.message)
    } else {
      setCardError(null)
    }
  }

  return { handleCardChange, cardComplete, cardError }
}
