import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"

export function YourPlanLoading() {
  return (
    <>
      <MSkeleton
        variant="rect"
        height={10}
        width={100}
        style={{ marginBottom: spacing.S }}
      />
      <MSkeleton
        variant="rect"
        height={20}
        width={200}
        style={{ marginBottom: spacing.S }}
      />
      <MSkeleton
        variant="rect"
        height={40}
        style={{ marginBottom: spacing.XL2 }}
      />
      <MSkeleton variant="rect" height={30} style={{ marginBottom: "30px" }} />
      <MSkeleton variant="rect" height={30} style={{ marginBottom: "30px" }} />
      <MSkeleton variant="rect" height={30} style={{ marginBottom: "30px" }} />
      <MSkeleton variant="rect" height={30} style={{ marginBottom: "30px" }} />
    </>
  )
}
