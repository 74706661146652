import { useState } from "react"
import styled from "styled-components"

import {
  AddonId,
  addonIsCallAssist,
  getAddonId,
  getAddonTotalCost,
  useAddonUtil,
} from "src/components/Account/BillingPortal/YourPlan/addonUtil"
import { RenewAddonDialog } from "src/components/Account/BillingPortal/YourPlan/RenewAddonDialog"
import {
  IChargebeeAddon,
  SubscriptionStatus,
} from "src/components/Account/types"
import { usePostCallAssistCancelInitiated } from "src/data/analytics/queries/CallAssistAnalyticsQueries"
import {
  TFetchCurrentSubscriptionResponse,
  TSubscriptionCurrencyCode,
} from "src/data/billing/types/billingTypes"
import { useFetchCallAssistSubscription } from "src/data/callAssist/queries/callAssistQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { translateBillingPeriod } from "src/data/user/logic/userPlan"
import { useTranslate } from "src/i18n/useTranslate"
import { TextButton } from "src/ui/Button/TextButton"
import { ExpandableSection } from "src/ui/ExpandableSection/ExpandableSection"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { formatPrice } from "src/utils/formatPrice"

import { CancelAddonDialog } from "./CancelAddonDialog"

type Props = {
  planStatus:
    | TFetchCurrentSubscriptionResponse["subscription_status"]
    | undefined
  addons: TFetchCurrentSubscriptionResponse["addons"]
  currencyCode: TSubscriptionCurrencyCode | undefined
  billingPeriodUnit: TFetchCurrentSubscriptionResponse["billing_period"]
}

export function YourPlanAddonsRow({
  planStatus,
  addons,
  currencyCode,
  billingPeriodUnit,
}: Props) {
  const { t, langKeys } = useTranslate()

  const { user_id: userId } = useGetUser()
  const { getAddonName } = useAddonUtil()
  const fetchCallAssistSubscription = useFetchCallAssistSubscription({ userId })
  const postCallAssistCancelInitiated = usePostCallAssistCancelInitiated()

  const [cancelAddon, setCancelAddon] = useState<IChargebeeAddon | null>(null)
  const [renewAddon, setRenewAddon] = useState<IChargebeeAddon | null>(null)

  const billingPeriod = translateBillingPeriod(
    t,
    billingPeriodUnit
  ).toLocaleLowerCase()
  const addonTotalPrice = getAddonTotalCost(addons ?? [])

  function handleCancel(a: IChargebeeAddon) {
    if (addonIsCallAssist(a)) {
      postCallAssistCancelInitiated.mutate()
    }
    setCancelAddon(a)
  }

  function handleRenew(a: IChargebeeAddon) {
    setRenewAddon(a)
  }

  const nonRenewing = planStatus === SubscriptionStatus.NON_RENEWING
  const showCancel = (a: IChargebeeAddon) => {
    if (nonRenewing || planStatus == null) {
      return false // for non-renewing plans it doesn't make sense to cancel individual addons
    }
    if (getAddonId(a.id) === AddonId.CallAssist) {
      return fetchCallAssistSubscription.data?.status === "active"
    }
    return false // for now, we don't handle other addon types
  }

  const showRenew = (a: IChargebeeAddon) => {
    if (getAddonId(a.id) === AddonId.CallAssist) {
      return fetchCallAssistSubscription.data?.status === "non_renewing"
    }
    return false // for now, we don't handle other addon types
  }

  if (fetchCallAssistSubscription.isInitialLoading) {
    return null
  }

  return (
    <Box>
      <ExpandableSection
        id="billing-addons"
        storeState
        title={
          <SectionTitle>
            <div>{t(langKeys.addons)}</div>
            <MText variant="body">
              {formatPrice(addonTotalPrice, currencyCode ?? "USD")}/
              {billingPeriod}
            </MText>
          </SectionTitle>
        }
      >
        <Contents>
          {addons?.map((addon) => {
            const addonCost = (addon.amount ?? 0) / 100
            const addonPrice = formatPrice(addonCost, currencyCode ?? "USD")

            return (
              <Row key={addon.id}>
                <RowTitle>
                  <span>{getAddonName(addon.id)}</span>

                  {showCancel(addon) && (
                    <TextButton onClick={() => handleCancel(addon)}>
                      {t(langKeys.cancel)}
                    </TextButton>
                  )}

                  {showRenew(addon) && (
                    <RenewSection>
                      <MBadge color="error">{t(langKeys.cancelled)}</MBadge>
                      <TextButton onClick={() => handleRenew(addon)}>
                        {t(langKeys.renew)}
                      </TextButton>
                    </RenewSection>
                  )}
                </RowTitle>
                <RowValue>
                  <div>
                    {addon.quantity}{" "}
                    {t(langKeys.home, {
                      count: addon.quantity,
                    }).toLocaleLowerCase()}
                  </div>
                  <Price>
                    {addonPrice}/{billingPeriod}
                  </Price>
                </RowValue>
              </Row>
            )
          })}
        </Contents>
      </ExpandableSection>

      <CancelAddonDialog
        open={!!cancelAddon}
        addon={cancelAddon}
        onClose={() => setCancelAddon(null)}
        onSuccess={() => setCancelAddon(null)}
      />

      <RenewAddonDialog
        addon={renewAddon}
        open={!!renewAddon}
        onClose={() => setRenewAddon(null)}
        onSuccess={() => setRenewAddon(null)}
      />
    </Box>
  )
}

const Box = styled.div``

const SectionTitle = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
`

const Contents = styled.div`
  text-align: start;
  display: grid;
  gap: ${spacing.M};
  padding-block: ${spacing.M};
`

const Row = styled.div`
  display: flex;
  column-gap: ${spacing.M};
  row-gap: ${spacing.XS2};
  flex-wrap: wrap;
`

const RowTitle = styled(MText).attrs(() => ({ variant: "subtitle" }))`
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  gap: ${spacing.S};
  flex-basis: 65%;
  white-space: nowrap;
  flex-wrap: wrap;
`

const RowValue = styled(MText).attrs(() => ({ variant: "body" }))`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${spacing.M};
  flex: 1 1 auto;
`

const RenewSection = styled.div`
  display: flex;
  column-gap: ${spacing.S};
`

const Price = styled.div`
  text-align: end;
`
