import { IChargebeeAddon } from "src/components/Account/types"
import { langKeys } from "src/i18n/langKeys"
import { TTranslateFunction, useTranslate } from "src/i18n/useTranslate"

export enum AddonId {
  CallAssist = "call-assist",
  GuardAssist = "guard-assist",
  ResponseService = "response-service",
}

function getAddonName(t: TTranslateFunction, _id: string) {
  const id = getAddonId(_id)

  switch (id) {
    case AddonId.CallAssist:
      return t(langKeys.call_assist)

    case AddonId.GuardAssist:
    case AddonId.ResponseService:
      return t(langKeys.response_service)

    default:
      return id
  }
}

export function getAddonId(id: string): string {
  const _id = id.toLowerCase()
  if (_id.includes(AddonId.CallAssist)) {
    return AddonId.CallAssist
  } else if (_id.includes(AddonId.GuardAssist)) {
    return AddonId.GuardAssist
  } else if (_id.includes(AddonId.ResponseService)) {
    return AddonId.GuardAssist
  }
  return _id
}

export function useAddonUtil() {
  const { t } = useTranslate()

  return {
    getAddonName: (id: string) => getAddonName(t, id),
    getAddonId,
  }
}

export function getAddonTotalCost(a: (IChargebeeAddon | null)[]) {
  return a.reduce((prev, curr) => prev + (curr?.amount ?? 0) / 100, 0)
}

export function addonIsCallAssist(addon: IChargebeeAddon) {
  return getAddonId(addon.id) === AddonId.CallAssist
}

export const debugAddons: IChargebeeAddon[] = [
  {
    id: "call-assist-fooCurrency", // tests addon id matching logic
    amount: 10000,
    quantity: 2,
    remaining_billing_cycles: 2,
  },
  {
    id: "guard-assist-example", // tests addon id matching logic
    amount: 50000,
    quantity: 3,
  },
  {
    id: "random-addon-1",
    amount: 100000,
    quantity: 5,
    remaining_billing_cycles: 0,
  },
  {
    id: "random-addon-2",
    amount: 20000,
    quantity: 10,
    remaining_billing_cycles: 1,
  },
]
