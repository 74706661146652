import { useState } from "react"
import styled from "styled-components"

import { useFetchOrCreateCustomer } from "src/data/billing/queries/billingQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { AddButton } from "src/ui/Button/AddButton"
import { TextButton } from "src/ui/Button/TextButton"
import { backgroundGrayV2 } from "src/ui/colors"
import { spacing } from "src/ui/spacing"

import { BillingAddressLoading } from "./BillingAddressLoading"
import { EditBillingDialog } from "./EditBillingDialog"
import { PrintedAddress } from "./PrintedAddress"

export function BillingAddress() {
  const { t } = useTranslate()
  const [showDialog, setShowDialog] = useState(false)
  const user = useGetUser()
  const fetchOrCreateCustomer = useFetchOrCreateCustomer({ user })

  if (fetchOrCreateCustomer.isLoading) {
    return <BillingAddressLoading />
  }

  const customer = fetchOrCreateCustomer.data

  if (!customer) {
    return null
  }

  const billingAddress = customer.billing_address

  return (
    <>
      {billingAddress ? (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <PrintedAddress
            billingAddress={billingAddress}
            vatNumber={customer.vat_number}
          />
          <TextButton
            onClick={() => setShowDialog(true)}
            style={{
              alignSelf: "flex-end",
              width: "fit-content",
              marginTop: spacing.S,
            }}
          >
            {t(langKeys.edit)}
          </TextButton>
        </div>
      ) : (
        <NoAddressBox>
          <AddButton onClick={() => setShowDialog(true)}>
            {t(langKeys.edit)}
          </AddButton>
        </NoAddressBox>
      )}

      <EditBillingDialog
        open={showDialog}
        billingAddress={billingAddress}
        vatNumber={customer.vat_number}
        customerId={customer.id}
        onClose={() => setShowDialog(false)}
      />
    </>
  )
}

const NoAddressBox = styled.div`
  padding: ${spacing.L};
  background: ${backgroundGrayV2};
  min-height: 130px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`
