import styled from "styled-components"

import { useTranslate } from "src/i18n/useTranslate"
import { ExternalLink } from "src/ui/Link/ExternalLink"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function NoPlan() {
  const { t, langKeys } = useTranslate()

  return (
    <Box>
      <div>
        <MText variant="subtitleS" color="secondary">
          {t(langKeys.your_plan)}
        </MText>
        <MText variant="heading2">
          {t(langKeys.subscription_no_plan_title)}
        </MText>

        <Description>
          <MText variant="bodyS" color="secondary">
            {t(langKeys.subscription_starter_description)}{" "}
            <ExternalLink href="https://minut.com/pricing">
              {t(langKeys.learn_more)}
            </ExternalLink>
          </MText>
        </Description>
      </div>
    </Box>
  )
}

const Description = styled.span`
  max-width: 100px;
`

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.L};
`
