import { formatUTCSecondsDate } from "src/components/Account/BillingPortal/ChangePlan/utils"
import { IInvoice } from "src/components/Account/types"
import { usePostCollectPayment } from "src/data/invoices/queries/InvoiceQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { semanticEmergency } from "src/ui/colors"
import { MDialog } from "src/ui/Dialog/MDialog"
import InfoIcon from "src/ui/icons/important-filled.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { ErrorService } from "src/utils/ErrorService"
import { formatPrice } from "src/utils/formatPrice"

export function PayInvoiceDialog({
  invoice,
  open = false,
  onClose,
  onSuccess,
}: {
  invoice?: IInvoice
  open: boolean
  onClose: () => void
  onSuccess: () => void
}) {
  const postCollectPayment = usePostCollectPayment()
  const { t } = useTranslate()

  // TODO: WEB-399  Show thank you page
  async function payInvoice(invoiceId: string) {
    postCollectPayment.mutate(
      { invoiceId },
      {
        onSuccess: () => {
          ErrorService.captureMessage(
            `Invoice ${invoiceId} was paid using the pay button`
          )
          onSuccess()
          onClose()
        },
        // eslint-disable-next-line no-console
        onError: console.log,
      }
    )
  }

  function waitForCollectPaymentClose() {
    if (postCollectPayment.isLoading) {
      return
    }
    onClose()
  }

  if (!invoice || !invoice?.amount_due || (invoice?.amount_due ?? 0) <= 0) {
    return (
      <MDialog
        title={t(langKeys.invoice_pay_now)}
        open={true}
        onClose={waitForCollectPaymentClose}
        confirmLabel={t(langKeys.close)}
        onConfirm={waitForCollectPaymentClose}
        error={`${t(langKeys.failed_to_load)}. ${t(
          langKeys.failed_contact_support
        )}`}
      />
    )
  }

  const error = postCollectPayment?.error
    ? `${t(langKeys.payment_failed)}. ${t(langKeys.payment_failed_help_funds)}`
    : null

  return (
    <MDialog
      title={t(langKeys.invoice_pay_now)}
      open={open}
      onClose={waitForCollectPaymentClose}
      confirmLabel={t(langKeys.invoice_pay_now)}
      onConfirm={() => payInvoice(invoice.id)}
      error={error}
      loading={postCollectPayment?.isLoading}
    >
      <MBanner type="error" style={{ marginBottom: spacing.M }} fullWidth>
        <InfoIcon
          fill={semanticEmergency}
          width={20}
          style={{ marginRight: spacing.XS2 }}
        />{" "}
        {t(langKeys.invoice_is_overdue)}
      </MBanner>

      <div>
        <MText variant="subtitle" as="span">
          {t(langKeys.date)}:
        </MText>{" "}
        {formatUTCSecondsDate(invoice.date)}
      </div>

      <div>
        <MText variant="subtitle" as="span">
          {t(langKeys.invoice_payment_due)}:
        </MText>{" "}
        {formatUTCSecondsDate(invoice.due_date)}
      </div>

      <div>
        <MText variant="subtitle" as="span">
          {t(langKeys.invoice_amount_due)}:
        </MText>{" "}
        <InfoIcon
          fill={semanticEmergency}
          width={15}
          height={15}
          style={{ marginLeft: spacing.XS2 }}
        />{" "}
        {formatPrice(invoice.amount_due / 100, invoice.currency_code)}
      </div>
    </MDialog>
  )
}
