import {
  IPaymentSource,
  PaymentSourceStatus,
} from "src/components/Account/types"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

export function PaymentMethodStatus({
  paymentMethod,
}: {
  paymentMethod?: IPaymentSource
}) {
  const { t } = useTranslate()

  if (paymentMethod?.status === PaymentSourceStatus.EXPIRING) {
    return (
      <MBanner fullWidth style={{ marginBottom: spacing.S }} type="error">
        {t(langKeys.payment_method_is_expiring)}
      </MBanner>
    )
  }

  if (paymentMethod?.status === PaymentSourceStatus.EXPIRED) {
    return (
      <MBanner fullWidth style={{ marginBottom: spacing.S }} type="error">
        {t(langKeys.payment_method_is_expired)}
      </MBanner>
    )
  }

  if (paymentMethod?.status === PaymentSourceStatus.INVALID) {
    return (
      <MBanner fullWidth style={{ marginBottom: spacing.S }} type="error">
        {t(langKeys.payment_method_is_invalid)}
      </MBanner>
    )
  }

  return null
}
