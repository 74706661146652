import { loadStripe } from "@stripe/stripe-js"

export enum StripeRegion {
  US = "us",
  UK = "uk",
  SE = "se",
}

export const mapRegionToStripePromise = {
  [StripeRegion.US]: loadStripe(
    import.meta.env.REACT_APP_STRIPE_API_KEY_US || ""
  ),
  [StripeRegion.SE]: loadStripe(
    import.meta.env.REACT_APP_STRIPE_API_KEY_SE || ""
  ),
  [StripeRegion.UK]: loadStripe(
    import.meta.env.REACT_APP_STRIPE_API_KEY_UK || ""
  ),
}

export function mapStripeRegionToGatewayId(stripeRegion: StripeRegion) {
  switch (stripeRegion) {
    case StripeRegion.SE: {
      return import.meta.env.REACT_APP_CHARGEBEE_GW_ACCOUNT_SE
    }
    case StripeRegion.US: {
      return import.meta.env.REACT_APP_CHARGEBEE_GW_ACCOUNT_US
    }
    case StripeRegion.UK: {
      return import.meta.env.REACT_APP_CHARGEBEE_GW_ACCOUNT_UK
    }
    default: {
      throw Error(`unrecognized stripe region: ${stripeRegion}`)
    }
  }
}

export function mapCountryToStripeRegion(countryCode?: string) {
  switch (countryCode?.toUpperCase()) {
    case "CA":
    case "US":
    case "AR":
    case "CL":
    case "GT":
    case "HK":
    case "IN":
    case "JP":
    case "MX":
    case "PE":
    case "SG":
    case "TH":
    case "TW":
    case "VN": {
      return StripeRegion.US
    }
    case "JE":
    case "GB": {
      return StripeRegion.UK
    }
    case "AU":
    case "NZ": {
      return StripeRegion.SE
    }
    default: {
      return StripeRegion.SE
    }
  }
}
