import styled from "styled-components"

import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { spacing } from "src/ui/spacing"

const HEIGHT = 25

export function PaymentsLoading() {
  return (
    <Box>
      <MSkeleton variant="rect" height={HEIGHT} />
      <MSkeleton variant="rect" height={HEIGHT} />
      <MSkeleton variant="rect" height={HEIGHT} />
      <MSkeleton variant="rect" height={HEIGHT} />
    </Box>
  )
}

const Box = styled.div`
  margin-top: ${spacing.XL};
  > * {
    margin-bottom: ${HEIGHT / 2}px;
  }
`
