import { Trans } from "react-i18next"

import { langKeys } from "src/i18n/langKeys"
import { Routes } from "src/router/routes"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"

export function BillingHistoryPlanRenewalNote({
  eventId,
}: {
  eventId: string
}) {
  return (
    <MText variant="bodyS">
      <Trans
        i18nKey={langKeys.billing_history_plan_renewal_note}
        components={{
          breakdownLink: (
            <InternalLink
              to={Routes.BillingEventDetails.location(eventId).pathname}
            />
          ),
        }}
      />
    </MText>
  )
}
