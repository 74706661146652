import { ReactElement, useState } from "react"
import styled from "styled-components"

import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"
import { StripeCardElement } from "@stripe/stripe-js"
import { AxiosError } from "axios"

import { BillingFields } from "src/components/Account/BillingPortal/BillingAddress/BillingFields"
import { transformBillingAddress } from "src/components/Account/BillingPortal/BillingAddress/saveAddress"
import { useHandleCardChange } from "src/components/Account/BillingPortal/PaymentMethod/useHandleCardChange"
import {
  mapStripeRegionToGatewayId,
  StripeRegion,
} from "src/components/Account/BillingPortal/PaymentMethod/utils"
import { IBillingAddress, ICustomer } from "src/components/Account/types"
import {
  usePostClientSecret,
  usePostCreatePaymentSourceWithIntent,
  usePostUpdateBillingInfo,
  usePutReplacePrimaryPaymentSource,
} from "src/data/billing/queries/billingQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { colorScale, mColors, systemEmergencyForeground } from "src/ui/colors"
import AmericanExpressIcon from "src/ui/icons/american-express.svg"
import MasterCardIcon from "src/ui/icons/mastercard.svg"
import VisaIcon from "src/ui/icons/visa.svg"
import { MBanner } from "src/ui/MBanner/MBanner"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { ErrorService } from "src/utils/ErrorService"

export function AddCardForm({
  customer,
  stripeRegion,
  billingAddress,
  vatNumber,
  setField,
  setVatNumber,
  replace,
}: {
  customer: ICustomer
  stripeRegion: StripeRegion
  billingAddress: IBillingAddress
  vatNumber: string
  setField: (fields: IBillingAddress) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  setVatNumber: (param: any) => void
  replace: boolean
}) {
  const { handleCardChange, cardComplete, cardError } = useHandleCardChange()
  const postClientSecret = usePostClientSecret()
  const [error, setError] = useState<ReactElement | string>("")
  const [submitting, setSubmitting] = useState(false)
  const postCreatePaymentSourceWithIntent =
    usePostCreatePaymentSourceWithIntent()
  const putReplacePrimaryPaymentSource = usePutReplacePrimaryPaymentSource()
  const postUpdateBillingInfo = usePostUpdateBillingInfo()

  const { t } = useTranslate()

  const stripe = useStripe()
  const elements = useElements()

  const formId = "add-payment-method-form"

  function handleSubmit(card: StripeCardElement | null | undefined) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
    return async (event: any) => {
      event.preventDefault()

      if (submitting || !cardComplete) {
        return
      }

      setError("")
      setSubmitting(true)

      try {
        if (!card) {
          throw Error("Something went wrong, card was undefined")
        }

        if (!customer) {
          throw Error("Something went wrong, customer was undefined")
        }

        const res = await postUpdateBillingInfo.mutateAsync({
          customerId: customer.id,
          body: {
            vat_number: vatNumber,
            billing_address: transformBillingAddress(billingAddress),
          },
          updateCache: false, // We don't want to update the cache, since that will cause issues with the stripe card element rerendering before it having a chance to confirm the information later on
        })
        const billing = res.customer.billing_address

        if (billing) {
          await saveCard(card, billing, customer.id, stripeRegion)
        } else {
          throw Error("Billing address is undefined")
        }
      } catch (error) {
        ErrorService.captureException(error)

        const err = error as AxiosError<{ error_msg: string }>

        if (err.response?.status === 400 && err.response?.data.error_msg) {
          setError(err.response.data.error_msg)
        } else if (err.message) {
          setError(err.message)
        } else {
          setError(<ErrorMessage />)
        }
      }

      setSubmitting(false)
    }
  }

  async function saveCard(
    card: StripeCardElement,
    billingAddress: IBillingAddress,
    customerId: string,
    stripeRegion: StripeRegion
  ) {
    const clientSecret = await getClientSecret(stripeRegion)
    const setupIntent = await confirmCard(card, billingAddress, clientSecret)
    const createNewPayment = replace
      ? putReplacePrimaryPaymentSource
      : postCreatePaymentSourceWithIntent

    return await createNewPayment.mutateAsync({
      body: {
        customer_id: customerId,
        payment_intent: {
          gateway_account_id: mapStripeRegionToGatewayId(stripeRegion),
          gw_token: setupIntent.id,
        },
      },
    })
  }

  async function getClientSecret(stripeRegion: StripeRegion): Promise<string> {
    const clientSecret = await postClientSecret.mutateAsync(stripeRegion)

    if (!clientSecret) {
      throw Error("Something went wrong, couldn't setup card")
    }

    return clientSecret
  }

  async function confirmCard(
    card: StripeCardElement,
    billingAddress: IBillingAddress,
    clientSecret: string
  ) {
    if (!stripe) {
      throw Error("Something went wrong, payment processor is not initialized")
    }

    const paymentMethod = {
      card,
      billing_details: {
        address: {
          postal_code: billingAddress?.zip,
          country: billingAddress?.country,
          line1: billingAddress?.line1,
          line2: billingAddress?.line2,
        },
        name: `${billingAddress?.first_name} ${billingAddress?.last_name}`,
        phone: billingAddress?.phone,
      },
    }

    const { setupIntent, error } = await stripe.confirmCardSetup(clientSecret, {
      payment_method: paymentMethod,
    })

    if (error || !setupIntent) {
      throw Error(error?.message ?? t(langKeys.failed_to_process_card))
    }

    return setupIntent
  }

  if (!stripe || !elements) {
    return null
  }

  return (
    <>
      <Form
        id={formId}
        onSubmit={handleSubmit(elements?.getElement(CardElement))}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <MText variant="subtitle">{t(langKeys.credit_card)}</MText>
          <PaymentOptions />
        </div>

        <CardBox $complete={cardComplete}>
          <CardElement options={CARD_OPTIONS} onChange={handleCardChange} />
        </CardBox>

        {cardError && (
          <div style={{ color: systemEmergencyForeground }}>{cardError}</div>
        )}

        <BillingFields
          billingAddress={billingAddress}
          vatNumber={vatNumber}
          setField={setField}
          setVatNumber={setVatNumber}
        />

        {error && (
          <MBanner type="error" fullWidth>
            {error}
          </MBanner>
        )}
      </Form>
      <MButton
        form={formId}
        type="submit"
        loading={submitting}
        disabled={submitting || !cardComplete}
        style={{ width: "100%", marginTop: spacing.S }}
      >
        {t(langKeys.save)}
      </MButton>
    </>
  )
}

function ErrorMessage() {
  const { t } = useTranslate()

  return (
    <>
      {t(langKeys.failed_to_process_card)}. {t(langKeys.failed_contact_support)}
    </>
  )
}

const CARD_OPTIONS = {
  style: {
    base: {
      color: mColors.textPrimary,
      "::placeholder": {
        color: mColors.textTertiary,
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
  hidePostalCode: true,
}

function PaymentOptions() {
  return (
    <PaymentOptionsBox>
      <VisaIcon />
      <MasterCardIcon />
      <AmericanExpressIcon />
    </PaymentOptionsBox>
  )
}

const PaymentOptionsBox = styled.div`
  display: flex;

  > * {
    margin-left: ${spacing.XS};
  }

  > svg {
    width: 33px;
    height: 28px;
  }
`

interface ICardBox {
  readonly $complete: boolean
}

const Form = styled.form`
  display: grid;
  gap: ${spacing.M};
`

const CardBox = styled.div<ICardBox>`
  .StripeElement {
    display: block;
    padding: ${spacing.M};
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-color: ${(props: ICardBox) =>
      props.$complete ? colorScale.good[200] : mColors.neutral};
    outline: 0;
    border-radius: 8px;
    background: ${(props: ICardBox) =>
      props.$complete ? colorScale.good[100] : mColors.neutral};
    color: red;
    font-size: 0.875rem;
  }

  .StripeElement--focus {
    -webkit-transition: all 150ms ease;
    transition: all 150ms ease;
  }
`
