import { usePostFetchCreditNotePdf } from "src/data/billing/queries/billingQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"
import { downloadFromUrl } from "src/utils/downloadUtil"
import { ErrorService } from "src/utils/ErrorService"

export function BillingHistoryPaymentRefundedNote({
  creditNoteId,
}: {
  creditNoteId: string
}) {
  const { t, langKeys } = useTranslate()
  const postFetchCreditNotePdf = usePostFetchCreditNotePdf()

  function handleDownload() {
    postFetchCreditNotePdf.mutate(
      {
        creditNoteId,
      },
      {
        onSuccess: (data) => {
          downloadFromUrl(data)
        },
        onError: (error) => {
          ErrorService.captureException(error)
        },
      }
    )
  }

  return (
    <div>
      <MButton onClick={handleDownload} variant="minimal">
        {t(langKeys.billing_history_payment_refunded_note_link_label)}
      </MButton>
    </div>
  )
}
