import styled from "styled-components"

import { BillingHistoryNote } from "src/components/Account/BillingPortal/BillingHistory/BillingHistoryNote"
import { TBillingHistoryResponse } from "src/data/billing/types/billingTypes"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { GridTable } from "src/ui/GridTable/GridTable"
import InfoIcon from "src/ui/icons/info-i.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"
import { formatDate, formatDateAsTime } from "src/utils/l10n"

export function BillingHistoryDesktopTable({
  events,
}: {
  events: TBillingHistoryResponse["events"]
}) {
  const user = useGetUser()

  const { t } = useTranslate()

  const columns = [
    <div key="event">{t(langKeys.billing_history_event)}</div>,
    <div key="home_name">{t(langKeys.home_name)}</div>,
    <TooltipWrapper key="payment">
      {t(langKeys.billing_history_credit_debit)}
      <MTooltip
        title={t(langKeys.billing_history_credit_debit_tooltip_description)}
      >
        <InfoIcon width={20} />
      </MTooltip>
    </TooltipWrapper>,
    <TooltipWrapper key="balance">
      {t(langKeys.billing_history_account_balance)}
      <MTooltip title={t(langKeys.billing_history_account_balance_tooltip)}>
        <InfoIcon width={20} />
      </MTooltip>
    </TooltipWrapper>,
    <div key="payment">{t(langKeys.billing_history_payment)}</div>,
    <div key="note">{t(langKeys.billing_history_note)}</div>,
    <div key="organization">{t(langKeys.organization)}</div>,
    <div key="home_group">{t(langKeys.billing_history_homegroup)}</div>,
    <div key="modified_by">{t(langKeys.billing_history_modified_by)}</div>,
  ]

  const rows = events.map((event) => (
    <>
      <div>
        <MText
          variant="subtitle"
          color={event.event_type === "home_deleted" ? "emergency" : "primary"}
        >
          {event.user_representation.description}
        </MText>
        <DateTimeWrapper>
          <MText variant="bodyS" color="secondary">
            {formatDate({ date: event.created_at, excludeTime: true })}
          </MText>
          <MText color="secondary">&bull;</MText>
          <MText variant="bodyS" color="secondary">
            {formatDateAsTime({
              date: event.created_at,
              clockType: user.clock_type,
              excludeSeconds: true,
            })}
          </MText>
        </DateTimeWrapper>
      </div>
      <MText variant="bodyS">
        {event.user_representation.home_name && event.home_id ? (
          <InternalLink to={Routes.Home.location(event.home_id)}>
            {event.user_representation.home_name}
          </InternalLink>
        ) : (
          "-"
        )}
      </MText>
      <MText variant="bodyS">
        {event.user_representation.credit_or_debit_amount || "-"}
      </MText>
      <MText variant="bodyS">{event.user_representation.balance || "-"}</MText>
      <MText variant="bodyS">
        {event.user_representation.payment_amount || "-"}
      </MText>
      <div>
        <BillingHistoryNote event={event} />
      </div>
      <MText variant="bodyS">
        {event.user_representation.organization_name || "-"}
      </MText>
      {event.user_representation.home_group && event.home_group_id ? (
        <InternalLink
          to={Routes.HomeGroupDetails.location(event.home_group_id)}
        >
          {event.user_representation.home_group}
        </InternalLink>
      ) : (
        <MText variant="bodyS">-</MText>
      )}
      <MText variant="bodyS">{event.user_representation.modified_by}</MText>
    </>
  ))

  return (
    <GridTable
      header={columns}
      rows={rows}
      mobileRows={rows}
      minWidth={0}
      templateColumns="repeat(5, minmax(max-content, auto)) 300px repeat(3, minmax(max-content, auto))"
      horizontalScroll
    />
  )
}

const TooltipWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS};
`

const DateTimeWrapper = styled.div`
  display: flex;
  gap: ${spacing.XS};
  align-items: center;
`
