import styled from "styled-components"

import { addDays, isWithinInterval } from "date-fns"

import { SubscriptionStatus } from "src/components/Account/types"
import { TFetchCurrentSubscriptionResponse } from "src/data/billing/types/billingTypes"
import { useDunning } from "src/data/user/logic/dunningLogic"
import { BILLING_PERIOD } from "src/data/user/user"
import { useTranslate } from "src/i18n/useTranslate"
import { TextButton } from "src/ui/Button/TextButton"
import { MBanner } from "src/ui/MBanner/MBanner"
import { spacing } from "src/ui/spacing"

export function YourPlanAlerts({
  subscription,
  setShowUndoCancelDialog,
  setShowUndoChangeDialog,
}: {
  subscription: TFetchCurrentSubscriptionResponse
  setShowUndoCancelDialog: (value: boolean) => void
  setShowUndoChangeDialog: (value: boolean) => void
}) {
  const { t, langKeys } = useTranslate()
  const dunningData = useDunning()
  const showPlanTransferNotice = willPlanAutoTransfer(subscription)

  return (
    <Alerts>
      {dunningData.alert && <MBanner type="error">{dunningData.alert}</MBanner>}

      {subscription.subscription_status === SubscriptionStatus.NON_RENEWING &&
        subscription.current_term_end && (
          <MBanner type="error" fullWidth>
            <>
              <p style={{ margin: `0 0 ${spacing.S}` }}>
                {t(langKeys.subscription_is_non_renewing, {
                  date: new Date(subscription.current_term_end),
                })}
              </p>
              <TextButton onClick={() => setShowUndoCancelDialog(true)}>
                {t(langKeys.subscription_remove_scheduled_cancellation)}
              </TextButton>
            </>
          </MBanner>
        )}

      {subscription.subscription_status === SubscriptionStatus.FUTURE && (
        <MBanner type="info" fullWidth>
          {t(langKeys.subscription_is_future)}
        </MBanner>
      )}

      {/* TODO: Allow cancelling change. */}
      {/* TODO: Show the changes that will be applied  */}
      {subscription.has_scheduled_changes && (
        <MBanner type="warning" fullWidth>
          <p style={{ margin: `0 0 ${spacing.S}` }}>
            {t(langKeys.subscription_has_scheduled_changes)}
          </p>
          <TextButton
            style={{ color: "inherit", borderColor: "inherit" }}
            onClick={() => setShowUndoChangeDialog(true)}
          >
            {t(langKeys.subscription_remove_scheduled_changes)}
          </TextButton>
        </MBanner>
      )}

      {showPlanTransferNotice && (
        <MBanner type="warning">
          {t(langKeys.transfer_legacy_plan_description, {
            name: subscription.plan,
          })}
        </MBanner>
      )}
    </Alerts>
  )
}

function willPlanAutoTransfer(subscription: TFetchCurrentSubscriptionResponse) {
  const isAnnualLegacyPlan =
    subscription?.legacy_plan &&
    subscription.billing_period === BILLING_PERIOD.year

  const nextBillingDate = subscription.next_billing_at
    ? new Date(subscription.next_billing_at)
    : null

  if (!isAnnualLegacyPlan || !nextBillingDate) return false

  const dateNow = new Date()

  const dateIsWithinInterval = isWithinInterval(nextBillingDate, {
    start: dateNow,
    end: addDays(dateNow, 21),
  })

  return dateIsWithinInterval
}

const Alerts = styled.div`
  display: grid;
  row-gap: ${spacing.S};
  > *:last-child {
    margin-bottom: ${spacing.M};
  }
`
