import { Elements } from "@stripe/react-stripe-js"

import { useBillingFields } from "src/components/Account/BillingPortal/BillingAddress/useBillingFields"
import { useMinutEmployee } from "src/components/Account/BillingPortal/ChangePlan/useMinutEmployee"
import { AddCardForm } from "src/components/Account/BillingPortal/PaymentMethod/AddCardForm"
import { ICustomer } from "src/components/Account/types"
import { CopyBox } from "src/ui/CopyText/CopyBox"

import { mapCountryToStripeRegion, mapRegionToStripePromise } from "./utils"

export function AddPaymentMethod({ customer }: { customer: ICustomer }) {
  const minut = useMinutEmployee()
  const billingAddressInitial = minut.testBillingAddress
    ? minut.testBillingAddress
    : customer.billing_address

  const { billingAddress, vatNumber, setField, setVatNumber } =
    useBillingFields(billingAddressInitial, customer.vat_number)

  const stripeRegion = mapCountryToStripeRegion(billingAddress?.country)

  return (
    <Elements
      stripe={mapRegionToStripePromise[stripeRegion]}
      key={stripeRegion}
    >
      {minut.eligible && (
        <CopyBox text={"4242 4242 4242 4242"} title="USD"></CopyBox>
      )}

      <AddCardForm
        customer={customer}
        stripeRegion={stripeRegion}
        billingAddress={billingAddress}
        vatNumber={vatNumber}
        setField={setField}
        setVatNumber={setVatNumber}
        replace={false}
      />
    </Elements>
  )
}
