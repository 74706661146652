import styled from "styled-components"

import { mColors } from "src/ui/colors"
import { BodySMixin } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export const Thead = styled.thead`
  ${BodySMixin};
  color: ${mColors.textTertiary};

  > tr {
    text-align: left;
  }
`

export const Table = styled.table`
  width: 100%;
  th {
    padding: ${spacing.S} ${spacing.S} 0;
  }

  th:first-child {
    padding-left: 0;
  }

  td {
    padding: ${spacing.S};
  }
`

export const Tbody = styled.tbody`
  tr {
  }

  tr:nth-of-type(odd) {
    background: ${mColors.neutralLight};
  }
`
