import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"

export function NeedHelpSection() {
  const { t, langKeys } = useTranslate()

  return (
    <div>
      <MText variant="subtitle">{t(langKeys.cancel_plan_need_help)}</MText>
      <div>
        <span>{t(langKeys.cancel_plan_need_help_body)}</span>
      </div>
    </div>
  )
}
