import { useState } from "react"
import styled from "styled-components"

import {
  countryData,
  getProvinceArray,
  isEuropeanUnionMemberState,
} from "src/components/Account/BillingPortal/countryData"
import { IBillingAddress } from "src/components/Account/types"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { AddButton } from "src/ui/Button/AddButton"
import { MSelect } from "src/ui/MSelect/MSelect"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export function BillingFields({
  billingAddress,
  vatNumber,
  setField,
  setVatNumber,
}: {
  vatNumber: string | undefined
  billingAddress: IBillingAddress | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  setField: (param: any) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- batch disable eslint any error
  setVatNumber: (param: any) => void
}) {
  const [showVatField, setShowVatField] = useState(
    vatNumber && vatNumber.length > 0
  )
  const provinces = getProvinceArray(billingAddress?.country)

  const { t } = useTranslate()

  function printOptional() {
    return `(${t(langKeys.input_optional)?.toLocaleLowerCase()})`
  }

  return (
    <Wrapper>
      <Row>
        <MTextField
          label={t(langKeys.first_name)}
          required
          autoComplete="given-name"
          value={valueWrapper(billingAddress?.first_name)}
          onChange={(value) => setField({ first_name: value })}
        />
        <MTextField
          label={t(langKeys.last_name)}
          autoComplete="family-name"
          required
          value={valueWrapper(billingAddress?.last_name)}
          onChange={(value) => setField({ last_name: value })}
        />
      </Row>

      <MTextField
        label={`${t(langKeys.address_company)} ${printOptional()}`}
        autoComplete="organization"
        value={valueWrapper(billingAddress?.company)}
        onChange={(value) => setField({ company: value })}
      />

      <MTextField
        label={t(langKeys.email)}
        type="email"
        autoComplete="email"
        required
        value={valueWrapper(billingAddress?.email)}
        onChange={(value) => setField({ email: value })}
      />

      <MTextField
        label={`${t(langKeys.phone)} ${printOptional()}`}
        // Should be phone
        type="text"
        autoComplete="tel"
        value={valueWrapper(billingAddress?.phone)}
        onChange={(value) => setField({ phone: value })}
      />

      <MTextField
        label={t(langKeys.address_form_street_name_1)}
        autoComplete="address-line-1"
        required
        value={valueWrapper(billingAddress?.line1)}
        onChange={(value) => setField({ line1: value })}
      />

      <MTextField
        label={`${t(langKeys.address_form_street_name_2)}`}
        autoComplete="address-line-2"
        value={valueWrapper(billingAddress?.line2)}
        onChange={(value) => setField({ line2: value })}
      />

      <Row>
        <MTextField
          label={`${t(langKeys.address_form_city)}`}
          autoComplete="address-level2"
          required
          value={valueWrapper(billingAddress?.city)}
          onChange={(value) => setField({ city: value })}
        />
        <MTextField
          label={t(langKeys.address_form_postcode)}
          autoComplete="postal-code"
          required
          value={valueWrapper(billingAddress?.zip)}
          onChange={(value) => setField({ zip: value })}
        />
      </Row>

      <Row>
        <MSelect
          label={t(langKeys.address_form_country)}
          value={valueWrapper(billingAddress?.country)}
          options={[
            { value: "", label: "", hidden: true },
            ...countryData.map((country) => ({
              key: country.code,
              label: country.label,
              value: country.code,
            })),
          ]}
          onChange={(selectedValue) => {
            setField({
              country: selectedValue,
            })
          }}
          autoComplete="country"
          required
        />

        {provinces.length > 0 && (
          <>
            <MSelect
              label={t(langKeys.address_form_state)}
              value={
                valueWrapper(billingAddress?.state_code) ||
                valueWrapper(billingAddress?.state)
              }
              options={[
                {
                  value: "",
                  label: "",
                  hidden: true,
                },
                ...provinces.map((province) => ({
                  key: province.code,
                  label: province.label,
                  value: province.code,
                })),
              ]}
              onChange={(selectedValue) => {
                setField({
                  state_code: selectedValue,
                })
              }}
              autoComplete="address-level1"
              required
            />
          </>
        )}
      </Row>

      {isVatExempt(billingAddress?.country) &&
        (showVatField ? (
          <Row>
            <MTextField
              autoComplete="organization-vat"
              placeholder=""
              value={valueWrapper(vatNumber)}
              startAdornment={<b>{valueWrapper(billingAddress?.country)}</b>}
              onChange={(value) => setVatNumber(value)}
            />
          </Row>
        ) : (
          <AddButton
            onClick={() => setShowVatField(true)}
            style={{ width: "fit-content" }}
          >
            {t(langKeys.billing_add_vat_number)}
          </AddButton>
        ))}
    </Wrapper>
  )
}

function valueWrapper(value?: string) {
  if (!value) {
    return ""
  }

  return value
}

function isVatExempt(countryCode: string | undefined): boolean {
  const _countryCode = countryCode?.toUpperCase()
  return isEuropeanUnionMemberState(_countryCode) && _countryCode !== "SE"
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`

const Row = styled.div`
  display: flex;

  > * {
    margin-left: ${spacing.XS2};
    margin-right: ${spacing.XS2};
  }

  *:first-child {
    margin-left: 0;
  }

  *:last-child {
    margin-right: 0;
  }
`
