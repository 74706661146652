import styled from "styled-components"

import { mColors } from "src/ui/colors"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function ButtonRating({
  value,
  min,
  max,
  labels,
  onClick,
}: {
  value: number | null
  min: number
  max: number
  labels?: {
    start?: string
    end?: string
  }
  onClick: (value: number) => void
}) {
  const arr = Array.from({ length: max - min + 1 }, (_, i) => i + min)

  return (
    <div>
      <RatingWrapper>
        {arr.map((v) => (
          <RatingButton
            key={v}
            $selected={v === value}
            onClick={() => onClick(v)}
            type="button"
          >
            {v}
          </RatingButton>
        ))}
      </RatingWrapper>
      <LabelsWrapper>
        <MText variant="bodyS">{labels?.start}</MText>
        <MText variant="bodyS">{labels?.end}</MText>
      </LabelsWrapper>
    </div>
  )
}

const RatingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${spacing.M};
  margin-bottom: ${spacing.S};
`

const LabelsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const RatingButton = styled.button<{ $selected: boolean }>`
  appearance: none;
  border: none;

  background-image: none;
  font: inherit;
  text-transform: none;
  margin: 0;
  outline: inherit;
  overflow: visible;
  line-height: 1.5;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background: ${({ $selected }) =>
    $selected ? mColors.primary : mColors.neutral};
  color: ${({ $selected }) =>
    $selected ? mColors.textContrast : mColors.textPrimary};
  border-radius: 9999px;

  cursor: pointer;
`
