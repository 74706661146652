import { useRef, useState } from "react"
import styled from "styled-components"

import { useResizeObserver } from "usehooks-ts"

import { BillingHistoryDesktopTable } from "src/components/Account/BillingPortal/BillingHistory/BillingHistoryDesktopTable"
import { BillingHistoryMobileTable } from "src/components/Account/BillingPortal/BillingHistory/BillingHistoryMobileTable"
import { PaymentsLoading } from "src/components/Account/BillingPortal/Payments/PaymentsLoading"
import { SearchFilter } from "src/components/Filter/SearchFilter"
import { Pager } from "src/components/Pager/Pager"
import { useFetchBillingHistory } from "src/data/billing/queries/billingQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

const BILLING_HISTORY_LIMIT = 12

export function BillingHistory() {
  const [searchValue, setSearchedValue] = useState("")
  const [offset, setOffset] = useState(0)

  const { orgId } = useOrganization()

  const fetchedBillingHistory = useFetchBillingHistory({
    orgId: orgId,
    filter: {
      search_billing_activity_filter: searchValue,
      offset,
      limit: BILLING_HISTORY_LIMIT,
    },
    options: {
      keepPreviousData: true,
    },
  })

  const { t } = useTranslate()

  const containerRef = useRef<HTMLDivElement>(null)
  const { width = 0 } = useResizeObserver({
    ref: containerRef,
    box: "border-box",
  })
  const showDesktopTable = width > 800

  const events = fetchedBillingHistory.data?.events || []

  return (
    <div ref={containerRef}>
      <SearchWrapper $fullWidth={!showDesktopTable}>
        <SearchFilter
          initialValue={searchValue}
          placeholder={t(langKeys.search)}
          onChange={(value) => setSearchedValue(value)}
        />
      </SearchWrapper>
      {!fetchedBillingHistory.isLoading ? (
        <>
          <TableWrapper>
            {showDesktopTable ? (
              <BillingHistoryDesktopTable events={events} />
            ) : (
              <BillingHistoryMobileTable events={events} />
            )}
          </TableWrapper>
          <MText
            variant="bodyS"
            color="secondary"
            textAlign="center"
            marginBottom={spacing.L}
          >
            {t(langKeys.billing_history_past_events_description)}
          </MText>
          <Pager
            offset={offset}
            limit={BILLING_HISTORY_LIMIT}
            totalCount={fetchedBillingHistory.data?.paging?.total_count}
            setOffset={setOffset}
            scrollOnClick={false}
          />
        </>
      ) : (
        <PaymentsLoading />
      )}
    </div>
  )
}

const SearchWrapper = styled.div<{ $fullWidth: boolean }>`
  max-width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "30ch")};
`

const TableWrapper = styled.div`
  margin-block: ${spacing.L};
`
