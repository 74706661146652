import styled from "styled-components"

import { useMutation } from "@tanstack/react-query"

import { formatUTCStringDate } from "src/components/Account/BillingPortal/ChangePlan/utils"
import {
  AddonId,
  getAddonId,
  useAddonUtil,
} from "src/components/Account/BillingPortal/YourPlan/addonUtil"
import { NeedHelpSection } from "src/components/Account/BillingPortal/YourPlan/NeedHelpSection"
import { IChargebeeAddon } from "src/components/Account/types"
import { useFetchCurrentSubscription } from "src/data/billing/queries/billingQueries"
import { usePatchCallAssistSubscription } from "src/data/callAssist/queries/callAssistQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog, TMDialogProps } from "src/ui/Dialog/MDialog"
import { spacing } from "src/ui/spacing"

type TRenewAddonDialogProps = {
  addon: IChargebeeAddon | null
  onSuccess: () => void
} & TMDialogProps

export function RenewAddonDialog({
  addon,
  onSuccess,
  ...props
}: TRenewAddonDialogProps) {
  const { t, langKeys } = useTranslate()
  const { getAddonName } = useAddonUtil()
  const renewAddon = useRenewAddon()
  const { user_id } = useGetUser()
  const fetchCurrentSubscription = useFetchCurrentSubscription({
    userId: user_id,
  })

  if (!addon) return null

  const addonName = getAddonName(addon.id)
  const billingPeriodEndDate = formatUTCStringDate(
    fetchCurrentSubscription.data?.next_billing_at
  )

  return (
    <MDialog
      responsive={false}
      {...props}
      title={t(langKeys.addon_undo_scheduled_cancellation)}
      confirmLabel={t(langKeys.confirm)}
      loading={renewAddon.isLoading}
      onConfirm={() => {
        renewAddon.mutate({ addonId: addon.id }, { onSuccess })
      }}
      onClose={() => {
        renewAddon.reset()
        props.onClose()
      }}
      error={
        renewAddon.isError && (
          <div>{t(langKeys.failed_something_went_wrong)}</div>
        )
      }
    >
      <ContentBox>
        <div>
          {t(langKeys.addon_will_automatically_renew_on_date, {
            addonName,
            billingPeriodEndDate,
          })}
        </div>
        <NeedHelpSection />
      </ContentBox>
    </MDialog>
  )
}

const ContentBox = styled.div`
  display: grid;
  gap: ${spacing.M};
`

function useRenewAddon() {
  const { user_id: userId } = useGetUser()
  const patchCallAssistSubscription = usePatchCallAssistSubscription({ userId })

  async function renewCallAssist() {
    await patchCallAssistSubscription.mutateAsync({ status: "active" })
  }

  async function renewAddon({ addonId }: { addonId: string }) {
    const id = getAddonId(addonId)

    switch (id) {
      case AddonId.CallAssist:
        await renewCallAssist()
        return "OK"

      default:
        throw Error(`Cancellation for '${addonId}' not implemented!`)
    }
  }

  return useMutation({ mutationFn: renewAddon })
}
