import { usePostFetchInvoicePdf } from "src/data/invoices/queries/InvoiceQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { MButton } from "src/ui/Button/MButton"

export function BillingHistoryPaymentNote({
  invoiceId,
}: {
  invoiceId: string
}) {
  const { t, langKeys } = useTranslate()
  const postFetchInvoicePdf = usePostFetchInvoicePdf()

  function handleDownload() {
    postFetchInvoicePdf.mutate(
      {
        invoiceId,
      },
      {
        onSuccess: (data) => {
          window.open(data, "_blank")
        },
        onError: (error) => {
          // eslint-disable-next-line no-console
          console.error(error)
        },
      }
    )
  }

  return (
    <div>
      <MButton onClick={handleDownload} variant="minimal">
        {t(langKeys.billing_history_payment_note_link_label)}
      </MButton>
    </div>
  )
}
