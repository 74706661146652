import {
  useDeletePaymentMethod,
  useFetchPaymentMethod,
} from "src/data/billing/queries/billingQueries"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog } from "src/ui/Dialog/MDialog"

export function RemoveCardDialog({
  paymentSourceId,
  open,
  onClose,
}: {
  paymentSourceId: string
  open: boolean
  onClose: () => void
}) {
  const { t } = useTranslate()
  const fetchPaymentMethod = useFetchPaymentMethod({
    paymentSourceId,
  })
  const deletePaymentMethod = useDeletePaymentMethod()

  const paymentMethod = fetchPaymentMethod.data

  async function handleConfirmClick() {
    if (paymentMethod) {
      deletePaymentMethod.mutate(
        {
          paymentSourceId: paymentMethod.id,
        },
        {
          onSuccess: onClose,
        }
      )
    }
  }

  if (!paymentMethod) {
    return null
  }

  const error = deletePaymentMethod?.error ? t(langKeys.failed_to_remove) : null
  const last4 = paymentMethod.card?.last4

  return (
    <MDialog
      title={t(langKeys.payment_method_remove)}
      open={open}
      onClose={onClose}
      loading={deletePaymentMethod?.isLoading}
      description={t(langKeys.payment_method_remove_confirm, { last4 })}
      confirmLabel={t(langKeys.payment_method_remove)}
      onConfirm={handleConfirmClick}
      error={error}
    />
  )
}
