import styled from "styled-components"

import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MainView } from "src/ui/Layout/MainView"
import { PageTitle } from "src/ui/Layout/PageTitle"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { ITab } from "src/ui/Tabs/Tab"
import { TabPanel } from "src/ui/Tabs/TabPanel"
import { Tabs } from "src/ui/Tabs/Tabs"

import { BillingPortal } from "./BillingPortal/BillingPortal"
import { RoundProfilePicture } from "./Avatars"
import { General } from "./General"

export function Account() {
  const { langKeys } = useTranslate()

  const user = useGetUser()
  const { orgAccessLogic } = useOrganization()

  const isOrgOwner = orgAccessLogic.hasOwnerAccess

  const tabs: ITab[] = [
    {
      id: Routes.AccountGeneral.location().pathname,
      label: langKeys.account,
      view: <General />,
    },
    {
      id: Routes.AccountBilling.location().pathname,
      label: langKeys.settings_billing,
      view: <BillingPortal />,
      hidden: !isOrgOwner,
    },
  ].filter((tab) => !tab?.hidden)

  return (
    <MainView
      title={
        <TitleBox>
          <RoundProfilePicture width={"55px"} height={"55px"} />

          <div>
            <StyledPageTitle>{user.fullname}</StyledPageTitle>
            <MText variant="body">{user.email}</MText>
          </div>
        </TitleBox>
      }
    >
      <Tabs labels={tabs} style={{ marginTop: spacing.M }} usePaths>
        {tabs.map((tab) => (
          <StyledTabPanel key={tab.id}>{tab.view}</StyledTabPanel>
        ))}
      </Tabs>
    </MainView>
  )
}

const StyledPageTitle = styled(PageTitle)`
  word-break: break-all;
`

const TitleBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: ${spacing.M};
`

const StyledTabPanel = styled(TabPanel)`
  margin-top: ${spacing.L};
`
