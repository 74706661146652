import styled from "styled-components"

import { IPaymentSource, PaymentSourceType } from "src/components/Account/types"
import { palette } from "src/ui/colors"
import ApplePayIcon from "src/ui/icons/apple-pay.svg"
import GooglePayIcon from "src/ui/icons/google-pay.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { CardBrandLogo } from "./CardBrandLogo"
import { PaymentMethodStatus } from "./PaymentMethodStatus"

export function CardImitation({
  paymentMethod,
}: {
  paymentMethod: IPaymentSource
}) {
  const { card } = paymentMethod

  return (
    <>
      <PaymentMethodStatus paymentMethod={paymentMethod} />

      <CardBox>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <LogoBox>{<CardBrandLogo brand={card?.brand} />}</LogoBox>
          {renderMobileWallet(paymentMethod.type)}
        </div>

        {card && (
          <div>
            <MaskedNumber>•••• •••• •••• {card.last4}</MaskedNumber>

            <Flex>
              <Value>
                {card.first_name} {card.last_name}
              </Value>
              <Value>
                {card.expiry_month} / {card.expiry_year}
              </Value>
            </Flex>
          </div>
        )}
      </CardBox>
    </>
  )
}

function renderMobileWallet(type?: PaymentSourceType) {
  if (!type) {
    return null
  }

  if (
    [PaymentSourceType.APPLE_PAY, PaymentSourceType.GOOGLE_PAY].includes(type)
  ) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <MText
          variant="bodyS"
          color="contrast"
          as="span"
          style={{
            marginRight: spacing.XS2,
          }}
        >
          provided via
        </MText>{" "}
        {type === PaymentSourceType.APPLE_PAY && (
          <LogoBox>
            <ApplePayIcon fill="white" />
          </LogoBox>
        )}
        {type === PaymentSourceType.GOOGLE_PAY && (
          <LogoBox>
            <GooglePayIcon />
          </LogoBox>
        )}
      </div>
    )
  }

  return null
}

const CardBox = styled.div`
  padding: ${spacing.S};
  background: ${palette.hejmo};
  background: #0f2027; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #2c5364,
    #203a43,
    #0f2027
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #2c5364,
    #203a43,
    #0f2027
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  color: white;
  border-radius: 14px;

  @media (min-width: 800px) {
    max-width: unset;
    padding: ${spacing.L};
  }
`

const LogoBox = styled.div`
  width: 40px;
  max-height: 40px;
`

const MaskedNumber = styled.div`
  margin: ${spacing.S} 0;

  @media (min-width: 800px) {
    margin: ${spacing.L} 0;
    font-size: 1.5rem;
  }
`

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`

const Value = styled.div`
  opacity: 0.8;
  font-size: 0.875rem;

  @media (min-width: 800px) {
    font-size: inherit;
  }
`
