import { CardBrand } from "src/components/Account/types"
import AmericanExpressIcon from "src/ui/icons/american-express.svg"
import MasterCardIcon from "src/ui/icons/mastercard.svg"
import VisaIcon from "src/ui/icons/visa.svg"

export function CardBrandLogo({
  brand,
  width,
  height,
}: {
  brand?: CardBrand
  width?: number
  height?: number
}) {
  switch (brand) {
    case CardBrand.VISA:
      return <VisaIcon width={width} height={height} />
    case CardBrand.MASTERCARD:
      return <MasterCardIcon width={width} height={height} />
    case CardBrand.AMERICAN_EXPRESS:
      return <AmericanExpressIcon width={width} height={height} />
    default:
      return null
  }
}
