import { useEffect, useState } from "react"

import { IBillingAddress } from "src/components/Account/types"

export function useBillingFields(
  billingAddress?: IBillingAddress,
  vatNumber?: string
) {
  const [_vatNumber, setVatNumber] = useState(vatNumber ?? "")
  const [_billingAddress, setBillingAddress] = useState<IBillingAddress>(
    initBillingAddress(billingAddress)
  )

  useEffect(() => {
    setVatNumber(vatNumber ?? "")
  }, [vatNumber])

  useEffect(() => {
    setBillingAddress(initBillingAddress(billingAddress))
  }, [billingAddress])

  function setField(fields: IBillingAddress) {
    setBillingAddress((prevState) => ({ ...prevState, ...fields }))
  }

  return {
    billingAddress: _billingAddress,
    vatNumber: _vatNumber,
    setField,
    setVatNumber,
  }
}

function initBillingAddress(billingAddress?: IBillingAddress) {
  return {
    first_name: billingAddress?.first_name ?? "",
    last_name: billingAddress?.last_name ?? "",
    email: billingAddress?.email ?? "",
    company: billingAddress?.company ?? "",
    phone: billingAddress?.phone ?? "",
    city: billingAddress?.city ?? "",
    country: billingAddress?.country ?? "",
    line1: billingAddress?.line1 ?? "",
    line2: billingAddress?.line2 ?? "",
    state: billingAddress?.state ?? "",
    state_code: billingAddress?.state_code ?? "",
    zip: billingAddress?.zip ?? "",
  }
}
