import { useState } from "react"
import styled from "styled-components"

import { Elements } from "@stripe/react-stripe-js"

import { useBillingFields } from "src/components/Account/BillingPortal/BillingAddress/useBillingFields"
import { AddCardForm } from "src/components/Account/BillingPortal/PaymentMethod/AddCardForm"
import {
  mapCountryToStripeRegion,
  mapRegionToStripePromise,
} from "src/components/Account/BillingPortal/PaymentMethod/utils"
import { ICustomer } from "src/components/Account/types"
import { TextButton } from "src/ui/Button/TextButton"
import { spacing } from "src/ui/spacing"

export function ReplacePaymentMethod({ customer }: { customer: ICustomer }) {
  const { billingAddress, vatNumber, setField, setVatNumber } =
    useBillingFields(customer.billing_address, customer.vat_number)

  const [replaceCard, setReplaceCard] = useState(false)

  const stripeRegion = mapCountryToStripeRegion(billingAddress?.country)

  return replaceCard ? (
    <CardFormBox>
      <Elements
        stripe={mapRegionToStripePromise[stripeRegion]}
        key={stripeRegion}
      >
        <AddCardForm
          customer={customer}
          stripeRegion={stripeRegion}
          billingAddress={billingAddress}
          vatNumber={vatNumber}
          setField={setField}
          setVatNumber={setVatNumber}
          replace={true}
        />
      </Elements>
    </CardFormBox>
  ) : (
    <ReplaceCardButton onClick={() => setReplaceCard(true)}>
      Replace Card
    </ReplaceCardButton>
  )
}

const CardFormBox = styled.div`
  margin-top: ${spacing.L};
`

const ReplaceCardButton = styled(TextButton)`
  margin-top: ${spacing.S};
  align-self: flex-end;
`
