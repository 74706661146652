import { IInvoice, InvoiceStatusType } from "src/components/Account/types"

export function isUnpaid(invoice: IInvoice) {
  return (
    invoice.status === InvoiceStatusType.NOT_PAID ||
    invoice.status === InvoiceStatusType.PAYMENT_DUE
  )
}

export function getAlertType(status: InvoiceStatusType) {
  if (status === InvoiceStatusType.PAID) {
    return "good"
  }

  if (status === InvoiceStatusType.PAYMENT_DUE) {
    return "warning"
  }

  return "error"
}
