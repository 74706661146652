import styled from "styled-components"

import { useMutation } from "@tanstack/react-query"

import { formatUTCStringDate } from "src/components/Account/BillingPortal/ChangePlan/utils"
import {
  AddonId,
  getAddonId,
  useAddonUtil,
} from "src/components/Account/BillingPortal/YourPlan/addonUtil"
import { IChargebeeAddon } from "src/components/Account/types"
import { usePostCallAssistCancelConfirmed } from "src/data/analytics/queries/CallAssistAnalyticsQueries"
import { useFetchCurrentSubscription } from "src/data/billing/queries/billingQueries"
import { usePatchCallAssistSubscription } from "src/data/callAssist/queries/callAssistQueries"
import { useGetUser } from "src/data/user/hooks/useGetUser"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog, TMDialogProps } from "src/ui/Dialog/MDialog"
import { spacing } from "src/ui/spacing"

import { NeedHelpSection } from "./NeedHelpSection"

type TCancelAddonDialogProps = {
  addon: IChargebeeAddon | null
  onSuccess: () => void
} & TMDialogProps

export function CancelAddonDialog({
  addon,
  ...props
}: TCancelAddonDialogProps) {
  if (!addon) return null

  return <CancelAddonDialogWithSideEffect addon={addon} {...props} />
}

function CancelAddonDialogWithSideEffect({
  addon,
  onSuccess,
  ...props
}: TCancelAddonDialogProps & { addon: IChargebeeAddon }) {
  const { t, langKeys } = useTranslate()
  const { getAddonName } = useAddonUtil()
  const { user_id } = useGetUser()

  const fetchCurrentSubscription = useFetchCurrentSubscription({
    userId: user_id,
  })
  const cancelAddon = useCancelAddon()

  const addonName = getAddonName(addon.id)
  const billingPeriodEndDate = formatUTCStringDate(
    fetchCurrentSubscription.data?.next_billing_at
  )

  return (
    <MDialog
      responsive={false}
      {...props}
      title={t(langKeys.cancel_addon_title, { addon: addonName })}
      confirmLabel={t(langKeys.cancel_addon_title, { addon: addonName })}
      confirmButtonProps={{ color: "destructive" }}
      loading={cancelAddon.isLoading}
      onConfirm={() => {
        cancelAddon.mutate({ addonId: addon.id }, { onSuccess })
      }}
      onClose={() => {
        cancelAddon.reset()
        props.onClose()
      }}
      error={
        cancelAddon.isError && (
          <div>{t(langKeys.failed_something_went_wrong)}</div>
        )
      }
    >
      <ContentBox>
        <div>
          {t(langKeys.cancel_addon_body, {
            addon: addonName,
            date: billingPeriodEndDate,
          })}
        </div>

        <NeedHelpSection />
      </ContentBox>
    </MDialog>
  )
}

const ContentBox = styled.div`
  display: grid;
  gap: ${spacing.M};
`

function useCancelAddon() {
  const { user_id: userId } = useGetUser()

  const postCallAssistCancelConfirmed = usePostCallAssistCancelConfirmed()
  const patchCallAssistSubscription = usePatchCallAssistSubscription({ userId })

  async function cancelCallAssist() {
    postCallAssistCancelConfirmed.mutate()
    await patchCallAssistSubscription.mutateAsync({ status: "non_renewing" })
  }

  async function cancelAddon({ addonId }: { addonId: string }) {
    const id = getAddonId(addonId)
    switch (id) {
      case AddonId.CallAssist:
        return await cancelCallAssist()

      default:
        throw Error(`Cancellation for '${addonId}' not implemented!`)
    }
  }

  return useMutation({ mutationFn: cancelAddon })
}
