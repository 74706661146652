import styled from "styled-components"

import { formatUTCStringDate } from "src/components/Account/BillingPortal/ChangePlan/utils"
import { useFetchPlanRenewalEstimate } from "src/data/billing/queries/billingQueries"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { MInfo } from "src/ui/Info/MInfo"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MCard } from "src/ui/MCard/MCard"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

export function PlanRenewal({
  userId,
  date,
}: {
  userId: string
  date?: string
}) {
  const fetchPlanRenewalEstimate = useFetchPlanRenewalEstimate({
    userId: userId,
  })

  const { t, langKeys } = useTranslate()

  if (fetchPlanRenewalEstimate.isLoading || fetchPlanRenewalEstimate.isError) {
    return null
  }

  const planRenewalEstimate = fetchPlanRenewalEstimate.data

  const formatedDate = formatUTCStringDate(date)

  return (
    <MCard border boxShadow={false}>
      <PlanRenewalEstimate $alignItems="flex-start">
        <div>
          <MText variant="subtitle" marginBottom={spacing.XS}>
            {t(langKeys.subscription_renewal)} {formatedDate}
          </MText>
          <PlanRenewalEstimateDescription>
            <MText>{t(langKeys.plan_renewal_estimate_description)}: </MText>
            <MText>{planRenewalEstimate.formatted_renewal_amount}</MText>
            <MInfo
              content={t(langKeys.plan_renewal_estimate_description_tooltip)}
              iconProps={{
                width: 20,
                height: 20,
              }}
              wrapperProps={{
                style: {
                  // This makes sure the wrapping div is as high as it needs rather than how high as the container, making the icon centered
                  display: "flex",
                },
              }}
            />
          </PlanRenewalEstimateDescription>
        </div>
        <InternalLink to={Routes.EstimatedRenewalDetails.location()}>
          {t(langKeys.plan_renewal_estimate_breakdown_link)}
        </InternalLink>
      </PlanRenewalEstimate>
    </MCard>
  )
}

const PlanRenewalEstimate = styled.div<{
  $alignItems: "flex-start" | "center"
}>`
  display: flex;
  align-items: ${({ $alignItems }) => $alignItems};
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${spacing.M};
`

const PlanRenewalEstimateDescription = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS2};
`
