import { HREF_MINUT_HELP } from "src/constants/hrefs"
import { langKeys } from "src/i18n/langKeys"
import { useTranslate } from "src/i18n/useTranslate"
import { ConfirmDialog } from "src/ui/Dialog/ConfirmDialog"
import { MLink } from "src/ui/Link/MLink"

export function UndoChangeDialog({
  open = false,
  onClose,
}: {
  open: boolean
  onClose: () => void
}) {
  const { t } = useTranslate()

  return (
    <ConfirmDialog
      title={t(langKeys.subscription_remove_scheduled_changes)}
      open={open}
      onClose={onClose}
      confirmLabel={t(langKeys.contact_support)}
      onConfirm={() => (window.location.href = HREF_MINUT_HELP)}
    >
      You'll soon be able to remove scheduled changes to your plan directly
      through the web app. In the mean time, contact support at{" "}
      <MLink href="mailto:hello@minut.com" color="unset">
        hello@minut.com
      </MLink>{" "}
      and we'll help you out.
    </ConfirmDialog>
  )
}
