import { usePostTrackEventWithParams } from "./analyticsQueries"

type TAnalyticsEventPlan =
  | "Plan Cancel Initiated" //
  | "Plan Cancel Complete"

export type TCancelReason =
  | "tech_problems"
  | "not_hosting"
  | "switching_solution"
  | "missing_features"
  | "not_meet_expectations"
  | "too_expensive"
  | "other"

export function usePostTrackCancelCompleteEvent() {
  return usePostTrackEventWithParams<
    TAnalyticsEventPlan,
    {
      // Must contain only the date with no time in the format "YYYY-MM-DD"
      // because it's sent eventually to Hubspot which does not accept datetime.
      cancellation_date: string
      cancel_reason: TCancelReason
      remaining_days: number
      experience_rating: number
      return_probability: number
      cancel_comment?: string
    }
  >({
    event: "Plan Cancel Complete",
  })
}

export function usePostTrackCancelInitEvent() {
  return usePostTrackEventWithParams<
    TAnalyticsEventPlan,
    { remaining_days: number }
  >({
    event: "Plan Cancel Initiated",
  })
}
